import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Seo from '../../components/Seo'
import SubLayout from '../../components/layouts/SubLayout'
import GridCardCollection from '../../components/GridCardCollection'

const LearningMethodsPage = ({ data }) => {
  const learningMethods = data.allSanityLearningMethod.nodes

  return (
    <SubLayout>
      <Seo title='Learning Methods' pathname='/learning-methods/' />
      <StyledLearningMethodsPage>
        <h1>Learning Methods</h1>

        <p className="breadcrumbs">
          <Link to='/'>Home</Link> {`>`}&nbsp;
          Learning Methods
        </p>

        <GridCardCollection data={learningMethods} directorySlug='learning-methods' />
      </StyledLearningMethodsPage>
    </SubLayout>
  )
}

export const learningMethodsPageQuery = graphql`
  query LearningMethodsPageQuery {
    allSanityLearningMethod(sort: {fields: title}) {
      nodes {
        title
        slug { current }
        featuredImage {
          asset { url }
        }
      }
    }
  }
`

const StyledLearningMethodsPage = styled.div`
  h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
`

export default LearningMethodsPage
