import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const GridCardCollection = ({ data, directorySlug }) => {
  return (
    <StyledGridCardCollection>
      {data.map(({title, slug, featuredImage}) => (
        <Link className='card' to={`/${directorySlug}/${slug.current}/`} key={slug.current}>
          <img src={featuredImage.asset.url} alt={title} title={title} />
          <h3>{title}</h3>
        </Link>
      ))}
    </StyledGridCardCollection>
  )
}

const StyledGridCardCollection = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 3rem;
  margin-top: 2rem;

  .card {
    background: rgb(0,0,0);
    background: linear-gradient(45deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 70%);
    width: 100%;
    padding-top: 60%;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    text-decoration: none;

    h3 {
      color: #f4f4f4;
      width: fit-content;
      margin: 2rem;
      font-size: 1.6rem;
      line-height: 1.8;
      position: absolute;
      bottom: 0;
      left: 0;

      &:before {
        content: '';
        background-color: var(--primary-color);
        height: 5px;
        width: calc(100% + 2rem);
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: transform 0.5s;
    }

    &:hover > img {
      transform: scale(1.1);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1.5rem;

    .card {
      h3 {
        margin: 1rem;
      }
    }
  }
`

export default GridCardCollection
